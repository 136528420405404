import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import useWindowDimensions from 'utils/windowsDimensions';

const Image = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

interface Props {
  imageUrl: string;
}

const CarouselPhotoSlide = ({ imageUrl }: Props) => {
  const { width: windowWidth } = useWindowDimensions();
  const imageRef = useRef<HTMLImageElement>(null);

  const keepAspectRatio = () => {
    if (imageRef.current) {
      const swiperSlide = imageRef.current.closest<HTMLElement>('.swiper-slide');
      if (swiperSlide) {
        const width = imageRef.current.width;
        swiperSlide.style.width = width + 'px';
      }
    }
  };

  useEffect(() => {
    keepAspectRatio();
  }, [windowWidth]);

  return <Image ref={imageRef} src={imageUrl} alt={'Photo'} onLoad={keepAspectRatio} />;
};

export default CarouselPhotoSlide;
