import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H2 } from 'components/typography/Headers';

export const Container = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 100px;
  padding: 24px 0;

  ${theme.mq.huge} {
    gap: 36px;
  }

  ${theme.mq.phone} {
    gap: 24px;
    padding: 0;
  }
`;

export const PaddedContainer = styled.div`
  padding: 0 24px;
  max-width: ${theme.maxWidthContainer}px;
  width: 100%;
  margin: 0 auto;

  ${theme.mq.phone} {
    padding: 12px;
  }
`;

export const FloatingSectionContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${theme.color.darkGray};
`;

export const ColumnsContainer = styled(PaddedContainer)`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  width: 100%;
  gap: 24px;

  ${theme.mq.tablet} {
    flex-direction: column;
    align-items: unset;
  }
`;

export const MainColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 2;
  max-width: 66.6%;
  align-items: center;

  ${theme.mq.tablet} {
    max-width: 100%;
  }
`;

export const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 33.3%;
  position: sticky;
  top: 24px;
  gap: 24px;
  margin-bottom: 24px;
  min-width: 360px;

  ${theme.mq.tablet} {
    max-width: 100%;
  }

  ${theme.mq.phone} {
    margin-bottom: 12px;
    min-width: unset;
  }
`;

export const Title = styled(H2)`
  margin: 48px 0;
  text-align: center;

  ${theme.mq.desktop} {
    margin: 32px 0;
  }

  ${theme.mq.tablet} {
    margin: 24px 0 0 0;
  }
`;

export const PostSomethingAndOwnerContainer = styled(PaddedContainer)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 24px;

  ${theme.mq.tablet} {
    flex-direction: column;
    align-items: unset;
    gap: 12px;
  }
`;
